import PropTypes from 'prop-types'
import React from "react"
import { config } from '../../helpers/config_global'

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

import { Link } from "react-router-dom"

// Reactstrap
import { Dropdown } from "reactstrap"

// Import menuDropdown
import AppBox from "../CommonForBoth/TopbarDropdown/AppBox"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import ServerTime from "../CommonForBoth/TopbarDropdown/ServerTime"

import logoSm from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-dark.png"
import logoLight from "../../assets/images/logo-light.png"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = props => {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    /* if (props.leftSideBarType === "default") {
      props.toggleLeftmenu(!props.leftMenu)
      props.changeSidebarType("condensed", isMobile)
    } else if (props.leftSideBarType === "condensed") {
      var body = document.body;
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    } */
    var body = document.body;
    if(!isMobile) {
      body.classList.toggle("vertical-collpsed");
    }
    body.classList.toggle("sidebar-enable");
    /* if (props.leftSideBarType === "default") {
      props.toggleLeftmenu(!props.leftMenu)
      props.changeSidebarType("condensed", isMobile)
    } */
    
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/* <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="42" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="40" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="42" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="40" />
                </span>
              </Link>
            </div> */}


            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars text-dark" />
            </button>

            <div className="position-relative">
              <Row className="d-none d-lg-inline-block ms-1" style={{paddingTop: 12}}>
                <Col className="pt-2"><h3>{config('appName').long}</h3></Col>
              </Row>
              <Row className="d-inline-block d-lg-none" style={{paddingTop: 6}}>
                <Col className="pt-3"><h3>{config('appName').short}</h3></Col>
              </Row>
            </div>
          </div>

          <div className="d-flex">
            {/* <AppBox /> */}
            <NotificationDropdown />
            <ProfileMenu />
            <ServerTime />
            <Dropdown className="d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="uil-minus-path text-dark"></i>
              </button>
            </Dropdown>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header)
