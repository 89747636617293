import { Subject } from "rxjs";
import apiResponse from "../../../../../services/apiResponse";
import DocumentExplorerRepository from "../Repositories/DocumentExplorerRepository";

class DocumentExplorerBloc {
  filesChannel = new Subject();
  directoryChannel = new Subject();
  documentChannel = new Subject()
  updateChannel = new Subject();
  createChannel = new Subject();
  deleteChannel = new Subject();
  createFolderChannel = new Subject();

  repository = new DocumentExplorerRepository();

  constructor() {
    this.filesChannel.next({ status: apiResponse.INITIAL });
  }

  fetchFiles = async (query, cancelToken) => {
    this.filesChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllDocuments()
        .then((result) => {
          this.filesChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.filesChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchDirectory = async (query) => {
    this.directoryChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchDirectory(query)
        .then((result) => {
          this.directoryChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.directoryChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {
      return;
    }
  };

  fetchDocument = async (query) => {
    this.documentChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchDocument(query)
        .then((result) => {
          this.documentChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.documentChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {
      return;
    }
  };

  updateDocument = async (query) => {
    this.updateChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .updateDocument(query)
        .then((result) => {
          this.updateChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.updateChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {
      return;
    }
  };

  createFolder = async (query) => {
    this.createFolderChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .createFolder(query)
        .then((result) => {
          this.createFolderChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.createFolderChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {
      return;
    }
  };

  createDocument = async (query) => {
    this.createChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .createDocument(query)
        .then((result) => {
          this.createChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.createChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {
      return;
    }
  };

  deleteDocument = async (query) => {
    this.deleteChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .deleteDocument(query)
        .then((result) => {
          this.deleteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.deleteChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {
      return;
    }
  };
}

export default DocumentExplorerBloc;

