import { apiWrapper, apiCancel } from '../../wrapper'

export const initial = 'user'
const baseUrl = process.env.REACT_APP_SERVICE_PORTAL_URL
const appCode = process.env.REACT_APP_SERVICE_PORTAL_APPCODE
const outputType = 'json'


export const userGetData = 'user/getData/'

export const cancelToken = () => {
    return apiCancel()
}
export const apiRequest = async (method, route, data = { params: null, body: null, cancelToken: null }, needToken=true) => {
    if(needToken) {
        const thisTime = (Math.floor(Date.now()/1000))
        const expire = localStorage.getItem(initial + 'accessExpired')
        if(expire < thisTime) {
            await apiWrapper(baseUrl, initial, appCode, outputType, 'post', 'auth/getAccessToken/', data)
            .then(result => {
                localStorage.setItem(initial + 'accessToken', result.response.access_token)
                return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
            })
            .catch(result => {
                return Promise.reject('Tidak dapat mengambil accessToken');
            })
        } else {
            return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)        
        }
    }
    return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data)
}
