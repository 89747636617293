import {forwardRef, useContext, useEffect} from "react";
import {Modal} from "reactstrap";
import {ExplorerContext} from "../../Context/ExplorerContext";
import Viewer, {Worker} from "@phuocng/react-pdf-viewer";

import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import "./BsModal.css";

import pdfjs from 'pdfjs-dist/build/pdf';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

const BsModal = forwardRef((props, ref) => {
    pdfjs.GlobalWorkerOptions.pdfjsWorker = pdfjsWorker;
    const {modal, setModal, imgUrl, pdfUrl, fileType} =
        useContext(ExplorerContext);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        console.log(imgUrl);
    }, []);
    
    const renderError = (error) => {
        let message = '';
        switch (error.name) {
            case 'InvalidPDFException':
                message = 'Dokumen tidak valid atau rusak';
                break;
            case 'MissingPDFException':
                message = 'Dokumen tidak dapat ditemukan';
                break;
            case 'UnexpectedResponseException':
                message = 'Tidak dapat terhubung ke server';
                break;
            default:
                message = 'Tidak dapat memuat dokumen';
                break;
        }
    
        return (
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#e53e3e',
                        borderRadius: '0.25rem',
                        color: '#fff',
                        padding: '0.5rem',
                    }}
                >
                    {message}
                </div>
            </div>
        );
    };

    return (
        <>
            <Modal
                isOpen={modal}
                toggle={toggle}
                className="modal-dialog-centered modal-lg img-modal"
            >
                <div className="modal-container">
                    {(() => {
                        if (fileType === "image") {
                            return <img src={imgUrl} alt="none" onError={renderError}/>;
                        } else if (fileType === "pdf") {
                            return (
                                <Worker>
                                    <div style={{height: "92vh"}}>
                                        <Viewer fileUrl={pdfUrl} renderError={renderError}/>
                                    </div>
                                </Worker>
                            );
                        }
                    })()}
                </div>
            </Modal>
        </>
    );
});

export default BsModal;
