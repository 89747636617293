import PropTypes from "prop-types";
import React from "react";

import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/WebRoutes";

// Import all middleware
import Basemiddleware from "./routes/middleware/BaseMiddleware";

// layouts Format
import DefaultLayout from "./components/DefaultLayout/Index";
import NonAuthLayout from "./components/NonAuthLayout";

import ErrorEmpty from "./components/Error/Empty";

// Import scss
import "./assets/scss/theme.scss";
// import "./assets/css/custom.css";
// import "./assets/css/line.css";
import "moment/locale/id";

const App = (props) => {
	return (
		<React.Fragment>
			<Router>
				<Switch>
					{authRoutes.map((route, idx) => (
						<Basemiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
							isAuthProtected={false}
						/>
					))}

					{userRoutes.map((route, idx) => (
						<Basemiddleware
							path={route.path}
							layout={DefaultLayout}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}

					<Basemiddleware layout={NonAuthLayout} component={ErrorEmpty} />
				</Switch>
			</Router>
		</React.Fragment>
	);
};

App.propTypes = {
	layout: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default connect(mapStateToProps, null)(App);
