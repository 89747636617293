import { Component } from "react";
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
} from "reactstrap";
import {
  AvField,
  AvForm,
} from "availity-reactstrap-validation";
import {
  FileInput,
  generateFormData
} from "../../../../../../helpers/ui/FileInput";
import { ConfirmAlert } from "../../../../../../helpers/ui/alert";
import apiResponse from "../../../../../../services/apiResponse";
import DocumentExplorerBloc from "../../Blocs/DocumentExplorerBloc";
import {ExplorerContext} from "../../Context/ExplorerContext";

class CreateFolderModal extends Component {
  static contextType = ExplorerContext;
  documentExplorerBloc = new DocumentExplorerBloc();
  userRole = parseInt(localStorage.getItem("currentRoleId"));
  userData = JSON.parse(localStorage.getItem("userdata"));

  constructor(props) {
    super(props);
    this.state = {
      dataForm: {
        employee_nip: this.userData.employee_nip,
      },
    };
  }

  handlerOnInputChange = (e) => {
    if (e.currentTarget) {
      this.setState({
        dataForm: {
          ...this.state.dataForm,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      });
    }
  };

  handlerOnSubmit = (e, values) => {
    e.preventDefault();

    this.setState({
      confirm: true,
      dataForm: {
        ...this.state.dataForm,
        ...values,
      },
    });

    this.setState({
      confirm_document_create: true,
    });
  };

  submitData = async () => {
    // console.log(this.state.dataForm);
    const formData = generateFormData(this.state.dataForm, {
      field: "file_attachment",
      name: "DMS"+this.context.currentFilePath,
    });

    // console.log(formData)
    await this.documentExplorerBloc.createFolder(formData);
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm_document_create: false,
        };
        break;
      case "confirm":
        forState = {
          loading_document_create: true,
          confirm_document_create: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success_document_create: false,
          redirect_document_update: true,
        };
        break;
      case "failed":
        forState = {
          failed_document_create: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.documentExplorerBloc.createFolderChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading_document_create: false,
            success_document_create: true,
          });
          setTimeout(() => {
            this.setState({
              success_document_create: false,
            });
            this.props.toggleModal();
            this.props.onSuccess()
          }, 1200);
          break;
        case apiResponse.ERROR:
          this.setState({
            loading_document_create: false,
            failed_document_create: true,
          });
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.documentExplorerBloc.createFolderChannel.unsubscribe();
  }

  renderCardForm = () => {
    return (
      <>
        <Card className="m-0">
          <CardBody>
            <CardTitle>Buat Folder</CardTitle>
            
            <hr />
            <AvForm
              className="needs-validation"
              onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
              onKeyPress={this.preventEnter}
            >

              <AvField
                    name="document_parent_uuid"
                    type="hidden"
                    id="document_parent_uuid"
                    value={this.context.currentFolderId}
                  />

<AvField
                    name="document_employee_uuid"
                    type="hidden"
                    id="document_employee_uuid"
                    value={this.context.currentEmployeeUuid}
                  />

<AvField
                    name="current_folder_name"
                    type="hidden"
                    id="current_folder_name"
                    value={this.context.currentFolderName}
                  />
                  
              <Row>
                <label
                  htmlFor="folder_name"
                  className="col-md-3 col-form-label"
                >
                  Nama Folder
                </label>
                <Col md="9">
                  <AvField
                    name="folder_name"
                    placeholder="Nama File"
                    type="text"
                    errorMessage="Masukkan nama folder"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    onChange={this.handlerOnInputChange}
                    id="folder_name"
                  />
                </Col>
              </Row>

              <br></br>

              <Row className="mt-3">
                <Col md="3"></Col>
                <Col md="9">
                  <Button
                    color="success"
                    onClick={this.props.toggleModal}
                  >
                    <i className="uil-arrow-left"></i> Kembali
                  </Button>{" "}
                  <Button color="danger" type="submit">
                    <i className="uil-save"></i> Simpan
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </CardBody>
        </Card>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm_document_create}
          showLoading={this.state.loading_document_create}
          showSuccess={this.state.success_document_create}
          showFailed={this.state.failed_document_create}
          response={this.confirmResponse}
        />
      </>
    );
  };

  render() {
    const {currentFolderId, currentEmployeeUuid, currentFolderName} =
        this.context;
    return (
      <Modal
        centered
        size="lg"
        toggle={this.props.toggleModal}
        isOpen={this.props.showModal}
        backdrop="static"
      >
        {this.props.showModal ? this.renderCardForm() : null}
      </Modal>
    );
  }
}

export default CreateFolderModal;
