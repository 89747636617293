import {ChonkyActions, FileHelper} from "chonky";

import {createContext, useCallback, useEffect, useState} from "react";

import {cancelToken} from "../../../../../services/adapters/document/documentService";
import apiResponse from "../../../../../services/apiResponse";
import DocumentExplorerBloc from "../Blocs/DocumentExplorerBloc";
import MinioClient from "../../../../../services/minioClient";

export const ExplorerContext = createContext();

export const ExplorerProvider = ({children}) => {
    const documentExplorerBloc = new DocumentExplorerBloc();
    const minio = new MinioClient();
    const userdata = JSON.parse(localStorage.getItem("userdata"));

    // United States, :)
    const [folderChain, setFolderChain] = useState([
        {
            id: process.env.REACT_APP_DOCUMENT_ROOT_UUID,
            name: "Dokumen",
            isDir: true,
        },
    ]);
    const [currentFolderId, setCurrentFolderId] = useState(
        process.env.REACT_APP_DOCUMENT_ROOT_UUID
    );
    const [currentEmployeeUuid, setCurrentEmployeeUuid]     = useState("");
    const [currentFolder, setCurrentFolder]                 = useState({});
    const [loadingFiles, setLoadingFiles]                   = useState(false);
    const [fileMap, setFileMap]                             = useState({});
    const [fileType, setFileType]                           = useState("");
    const [files, setFiles]                                 = useState([null, null, null]);
    const [modal, setModal]                                 = useState(false);
    const [imgUrl, setImgUrl]                               = useState("");
    const [pdfUrl, setPdfUrl]                               = useState("");
    const [source, setSource]                               = useState(cancelToken());
    const [createFolderId, setCreateFolderId]               = useState("create_folder");
    const [createFileId, setCreateFileId]                   = useState("create_file");
    const [editFilesId, setEditFilesId]                     = useState("edit_files");
    const [deleteFileId, setDeleteFileId]                   = useState("delete_file");
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
    const [showCreateFileModal, setShowCreateFileModal]     = useState(false);
    const [showEditFileModal, setShowEditFileModal]         = useState(false);
    const [showDeleteFileModal, setShowDeleteFileModal]     = useState(false);
    const [currentFileUuid, setCurrentFileUuid]             = useState("");
    const [currentFileName, setCurrentFileName]             = useState("");
    const [currentFilePath, setCurrentFilePath]             = useState("");
    const [showEditFailedModal, setShowEditFailedModal]     = useState(false);
    const [currentFolderName, setCurrentFolderName]         = useState("");

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        setTokenAPI(currentFolderId, userdata.employee_uuid, queryParams.get("employee_nip"));

        // rxjs implementation
        documentExplorerBloc.directoryChannel.subscribe((result) => {            
            switch (result.status) {
                case apiResponse.INITIAL:
                    setLoadingFiles(false);
                    break;
                case apiResponse.LOADING:
                    setLoadingFiles(true);
                    break;
                case apiResponse.COMPLETED:
                    setLoadingFiles(false);
                    const response = result.data.response;

                    if (response.directory === null) {
                        setFiles([]);
                    } else if (response.directory !== null && response.directory.id !== currentFolderId){
                        setCurrentFolderId(response.directory.id);
                        setCurrentEmployeeUuid(response.directory.employeeUuid);
                    }
                        
                    if (files[0] === null) {
                        setCurrentFolder(response.directory);
                        setFileMap(response.files);
                        setFolderChain(response.folderChain);
                    }

                    break;
                case apiResponse.ERROR:
                    console.log("error");
                    break;
                default:
                    break;
            }
        });
    }, []);

    // the side effect of fileMap, it's watching fileMap state changes
    // and fire the fetchFiles() when fileMap state change
    useEffect(() => {
        fetchFiles(currentFolder);
    }, [fileMap]);

    // the file action handler custom hook, it's return useCallback hook
    const useFileActionHandler = (setCurrentFolderId) => {
        return useCallback(
            // this hook will be fired whenever setCurrenFolderId is fired
            async (data) => {
                // yet, only open file(s) action is handled
                // check is the fired action (data) is a open file(s) action
                if (data.id === ChonkyActions.OpenFiles.id) {
                    // then destructure the payload and define the fileToOpen
                    const {targetFile, files} = data.payload;
                    const fileToOpen = targetFile ?? files[0];
                    const filePath = await minio.get((fileToOpen.isFromExternal == 0 ? "DMS" : "") + fileToOpen.path);

                    // if the fileToOpen is a directory, then open it. simple
                    if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                        // call those functions to do request and re-render the file browser
                        setTokenAPI(fileToOpen.id, userdata.employee_uuid);
                        setCurrentFolderId(fileToOpen.id);
                        setCurrentFolderName(fileToOpen.name);
                        fetchFiles(currentFolder);
                        return;
                    } else if (fileToOpen && fileToOpen.mime.split("/")[0] === "image") {
                        setFileType("image");
                        setImgUrl(filePath);
                        setModal(true);
                        return;
                    } else if (fileToOpen && fileToOpen.mime.split("/")[1] === "pdf") {
                        setFileType("pdf");
                        setPdfUrl(filePath);
                        setModal(true);
                        return;
                    }
                }

                if (data.id === ChonkyActions.DownloadFiles.id) {
                    // then destructure the payload and define the fileToOpen
                    const targetFile = data.state.selectedFiles[0];
                    const filePath = await minio.get((targetFile.isFromExternal == 0 ? "DMS" : "") + targetFile.path);

                    filePath && !FileHelper.isDirectory(targetFile)
                        ? window.open(filePath)
                        : alert("Error");
                }

                if (data.id === editFilesId) {
                    if (data.state.selectedFiles[0].isDir == true) {
                        setShowEditFailedModal(true)
                    } else {
                        setCurrentFileName(data.state.selectedFiles[0].name);
                        
                        //remove file name
                        var sourcePath = data.state.selectedFiles[0].path;
                        var lastIndex=sourcePath.lastIndexOf("/");
                        var path=sourcePath.slice(0,lastIndex+1);

                        setCurrentFileUuid(data.state.selectedFiles[0].id);
                        setCurrentFilePath(path);
                        setShowEditFileModal(true);
                    }
                }

                if (data.id === createFileId) {
                    setShowCreateFileModal(true);
                }

                if (data.id === deleteFileId) {
                    setCurrentFileUuid(data.state.selectedFiles[0].id);
                    setShowDeleteFileModal(true);
                }

                if (data.id === createFolderId) {
                    setShowCreateFolderModal(true);
                }
        
            },
            [setCurrentFolderId]
        );
    };

    // main function for files navigation. it's fetch the files to the state from server request
    // it'll file others function by its side effect
    const fetchFiles = (currentFolder) => {
        if (currentFolder !== null && currentFolder.id) {
            // check if current folder has child(s), then map if it has
            const tmpFiles = currentFolder.childrenIds
                ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ?? null)
                : [];

            // map tmpFiles's filesize to get integer value
            if (tmpFiles.length > 0) {
                const newFiles = tmpFiles.map((file) => {
                    if (file.size) {
                        return {
                            ...file,
                            size: parseInt(file.size),
                        };
                    } else {
                        return file;
                    }
                });

                setFiles(newFiles);
            } else {
                setFiles([]);
            }
        }
    };

    // when this function fired, it'll make a request to the server as it's calling loadData()
    const setTokenAPI = (dir, user, employee_nip) => {
        if (typeof source != typeof undefined) {
            source.cancel();
        }
        setSource(cancelToken());
        loadData({dir, user, employee_nip});
    };

    // request data to the server
    const loadData = async (query) => {
        await documentExplorerBloc.fetchDirectory(query, source.token);
    };

    // this const is handling the file action using custom hook
    const handleFileAction = useFileActionHandler(setCurrentFolderId);

    const refreshData = () => {
        setTokenAPI(currentFolderId, userdata.employee_uuid)

        documentExplorerBloc.directoryChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.INITIAL:
                    setLoadingFiles(false);
                    break;
                case apiResponse.LOADING:
                    setLoadingFiles(true);
                    break;
                case apiResponse.COMPLETED:
                    setLoadingFiles(false);
                    const response = result.data.response;
                    
                    setCurrentFolder(response.directory);
                    setFileMap(response.files);
                    setFolderChain(response.folderChain);

                    break;
                case apiResponse.ERROR:
                    console.log("error");
                    break;
                default:
                    break;
            }
        });
    }

    return (
        <ExplorerContext.Provider
            value={{
                files,
                setFiles,
                fileMap,
                folderChain,
                loadingFiles,
                currentFolder,
                currentFolderId,
                fileType,
                imgUrl,
                pdfUrl,
                modal,
                setModal,
                handleFileAction,
                createFolderId,
                createFileId,
                editFilesId,
                deleteFileId,
                showCreateFolderModal,
                setShowCreateFolderModal,
                showEditFileModal,
                setShowEditFileModal,
                showCreateFileModal,
                setShowCreateFileModal,
                showDeleteFileModal,
                setShowDeleteFileModal,
                currentFileUuid,
                currentFileName,
                currentFilePath,
                showEditFailedModal,
                setShowEditFailedModal,
                refreshData,
                currentEmployeeUuid,
                currentFolderName
            }}
        >
            {children}
        </ExplorerContext.Provider>
    );
};

export default ExplorerProvider;
