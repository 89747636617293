import { apiWrapper, apiCancel } from "../../wrapper";

export const initial = "document";

const baseUrl = process.env.REACT_APP_SERVICE_DOCUMENT_URL;
const appCode = process.env.REACT_APP_SERVICE_DOCUMENT_APPCODE;
const outputType = "json";

export const accessCreate = "access/create/";

// get document(s)
export const getAllDocuments = "document/getAllDocuments/";
export const getDocument = "document/getDocument/";
export const getDirectory = "document/getDirectory/";

// post / put / patch / delete document(s)
export const createDocument = "document/createDocument/";
export const updateDocument = "document/updateDocument/";
export const deleteDocument = "document/deleteDocument/";
export const createFolder = "document/createFolder/";

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
  return apiCancel();
};

export const apiRequest = async (
  method,
  route,
  data = { params: null, body: null, cancelToken: null },
  needToken = true
) => {
  if (needToken) {
    const thisTime = Math.floor(Date.now() / 1000);
    const expire = localStorage.getItem(initial + "accessExpired");

    if (expire < thisTime) {
      await apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        "post",
        "auth/getAccessToken/",
        data
      )
        .then((result) => {
          localStorage.setItem(
            initial + "accessToken",
            result.response.access_token
          );

          return apiWrapper(
            baseUrl,
            initial,
            appCode,
            outputType,
            method,
            route,
            data
          );
        })

        .catch((result) => {
          return Promise.reject("Tidak dapat mengambil accessToken");
        });
    } else {
      return apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        method,
        route,
        data
      );
    }
  }

  return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data);
};

