import React, {Fragment} from "react";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";

import {FileExplorer} from "./Components/FileExplorer/FileExplorer";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {ExplorerProvider} from "./Context/ExplorerContext";

const IndexView = () => {
    return (
        <Fragment>
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <ExplorerProvider>
                                    <FileExplorer/>
                                </ExplorerProvider>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default IndexView;
