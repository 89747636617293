import React from "react"
import { Redirect } from "react-router-dom"

import Logout from "../pages/Authentication/Logout"

// Document
import DocumentExplorer from "../pages/Document/Explorer/RouteView"

//Setting
import SettingConfig from "../pages/Setting/Config/RouteView"
import SettingUser from "../pages/Setting/User/RouteView"
import SettingAccessRole from "../pages/Setting/AccessRole/RouteView"
import SettingActivity from "../pages/Setting/Activity/RouteView"

const userRoutes = [

  //base
  { path: "/", exact: true, component: () => <Redirect to="/document/explorer.html" /> },
  { path: "/document/explorer.html", component: DocumentExplorer },
]

const authRoutes = [

  { path: "/logout.html", component: Logout },

]

export { userRoutes, authRoutes }
