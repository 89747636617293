import {
  apiRequest,
  getAllDocuments,
  getDocument,
  createDocument,
  updateDocument,
  getDirectory,
  deleteDocument,
  createFolder
} from "../../../../../services/adapters/document/documentService";

class DocumentExplorerRepository {
  fetchAllDocuments = async (query = {}) => {
    return await apiRequest(
      "get",
      getAllDocuments,
      {
        params: query,
      },
      true
    );
  };

  fetchDirectory = async (query = {}) => {
    return await apiRequest(
      "get",
      getDirectory,
      {
        params: query,
      },
      true
    );
  };

  fetchDocument = async (query) => {
    return await apiRequest(
      "get",
      getDocument,
      {
        params: query,
      },
      true
    );
  };

  createDocument = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      createDocument,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };

  updateDocument = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      updateDocument,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };

  deleteDocument = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      deleteDocument,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };

  createFolder = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      createFolder,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };
}

export default DocumentExplorerRepository;

