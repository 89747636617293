export function endpoint(type) {
  switch (type) {
    case "adal":
      return {
        tenant: process.env.REACT_APP_ADAL_TENANT,
        clientId: process.env.REACT_APP_ADAL_CLIENT_ID,
        endpoints: {
          api: process.env.REACT_APP_ADAL_ENDPOINTS_API,
        },
        redirectUri: process.env.REACT_APP_ADAL_REDIRECT_URI,
        cacheLocation: "sessionStorage",
      };
    case "minio":
      return {
        bucket: process.env.REACT_APP_MINIO_DEFAULT_BUCKET,
        credential: {
          endPoint: process.env.REACT_APP_MINIO_ENDPOINT,
          port: parseInt(process.env.REACT_APP_MINIO_PORT),
          useSSL: process.env.REACT_APP_MINIO_USE_SSL === "true" ? true : false,
          accessKey: process.env.REACT_APP_MINIO_ACCESS_KEY,
          secretKey: process.env.REACT_APP_MINIO_SECRET_KEY,
        },
      };
    default:
      return null;
  }
}
