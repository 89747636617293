import {useContext, useEffect, useState} from "react";
import {
    ChonkyActions,
    ChonkyIconName,
    defineFileAction,
    FileViewMode,
    FullFileBrowser,
} from "chonky";

import {ExplorerContext} from "../../Context/ExplorerContext";
import EditFileModal from "../EditFileModal";
import CreateFileModal from "../CreateFileModal";
import {Icon} from "@iconify/react";
import BsModal from "../BsModal/BsModal";
import { ConfirmAlert } from "../../../../../../helpers/ui/alert";
import apiResponse from "../../../../../../services/apiResponse";
import DocumentExplorerBloc from "../../Blocs/DocumentExplorerBloc";

import "./FileExplorer.css";
import CreateFolderModal from "../CreateFolderModal";

export const FileExplorer = () => {
    const {files, folderChain, handleFileAction, createFolderId, createFileId, editFilesId, deleteFileId, showCreateFolderModal, setShowCreateFolderModal, showCreateFileModal, setShowCreateFileModal, showEditFileModal, setShowEditFileModal, showDeleteFileModal, setShowDeleteFileModal, showEditFailedModal, setShowEditFailedModal, currentFileUuid, refreshData} = useContext(ExplorerContext);
    const [filesToShow, setFilesToShow] = useState([...files]);
    const [searchForm, setSearchForm] = useState("");
    const [deleteFileLoading, setDeleteFileLoading] = useState(false);
    const [deleteFileSuccess, setDeleteFileSuccess] = useState(false);
    const [deleteFileFailed, setDeleteFileFailed] = useState(false);
    const documentExplorerBloc = new DocumentExplorerBloc();
    const roleId = localStorage.getItem("currentRoleId");
    const ADMIN = 2;
    const PEGAWAI = 1;


    // append the custom search form to chonky's DOM
    useEffect(() => {
        const leftToolbar = document.querySelector(".chonky-toolbarLeft");
        const searchBar = document.getElementById("chonky-searchbar")

        leftToolbar.appendChild(searchBar);
    }, [])
      
    useEffect(() => {
        documentExplorerBloc.deleteChannel.subscribe((result) => {
            switch (result.status) {
              case apiResponse.COMPLETED:
                setDeleteFileLoading(false);
                setDeleteFileSuccess(true);
                refreshFiles()
                break;
              case apiResponse.ERROR:
                setDeleteFileLoading(false);
                setDeleteFileFailed(true);
                break;
              default:
                break;
            }
          });
    }, [documentExplorerBloc.deleteChannel])

    // update filesToShow and truncate search form on files (from context) change
    useEffect(() => {
        setFilesToShow(files);
        setSearchForm("");
    }, [files])

    // on search form change event handler
    const onSearchChange = (e) => {
        const target = e.currentTarget;
        const value = target.value;

        setSearchForm(value);

        value ? search(value) : setFilesToShow(files);
    }

    const toggleModal = () => {
        setShowEditFileModal(!showEditFileModal);
    };

    const toggleCreateModal = () => {
        setShowCreateFileModal(!showCreateFileModal)
    }

    const toggleCreateFolderModal = () => {
        setShowCreateFolderModal(!showCreateFolderModal)
    }

    // search action using filter array function
    const search = (query) => {
        setFilesToShow(files.filter((item) => {
            return item.name.toLowerCase().includes(query.toLowerCase())
        }))

        return filesToShow.length > 0
    }

    const deleteData = async () => {
        const formData = {
            "document_uuid": currentFileUuid
        };

        await documentExplorerBloc.deleteDocument(formData);
    };

    // disabled
    const smallIconsGrid = defineFileAction({
        id: "enable_grid_view",
        fileViewConfig: {
            mode: FileViewMode.Grid,
            entryWidth: 120,
            entryHeight: 100,
        },
        button: {
            name: "Switch to Grid view",
            toolbar: false,
            icon: ChonkyIconName.smallThumbnail,
            iconOnly: true,
        },
    });

    // disabled
    const iconsList = defineFileAction({
        id: "enable_list_view",
        fileViewConfig: {
            mode: FileViewMode.List,
            entryHeight: 30,
        },
        button: {
            name: "Switch to List view",
            toolbar: false,
            icon: ChonkyIconName.list,
            iconOnly: true,
        },
    });

    const createFolder = defineFileAction({
        id: createFolderId,
        button: {
          name: "Buat Folder",
          contextMenu: false,
          toolbar: true,
          icon: ChonkyIconName.folderCreate
        }
    });

    const createFile = defineFileAction({
        id: createFileId,
        button: {
          name: "Upload File",
          contextMenu: false,
          toolbar: true,
          icon: ChonkyIconName.upload
        }
    });

    const editFiles = defineFileAction({
        id: editFilesId,
        button: {
          name: "Edit",
          contextMenu: true,
          icon: ChonkyIconName.archive
        }
    });

    const deleteFile = defineFileAction({
        id: deleteFileId,
        button: {
          name: "Delete",
          contextMenu: true,
          icon: ChonkyIconName.trash
        }
    });


    const actionsToDisable = [
        ChonkyActions.SelectAllFiles.id,
        ChonkyActions.ToggleHiddenFiles.id,
    ];

    const confirmResponse = (response) => {
        let forState = {};
        switch (response) {
          case "failed":
            setShowEditFailedModal(false)
            break;
          default:
        }        
      };

    const confirmDeleteResponse = (response) => {
        switch (response) {
            case "cancel":
                setShowDeleteFileModal(false)
                break;
            case "confirm":
                setShowDeleteFileModal(false)
                setDeleteFileLoading(true)
                deleteData()
            break;
            case "success":
                setDeleteFileSuccess(false);
            break;
            case "failed":
                setDeleteFileFailed(false);
            break;
            default:
        }
    
    };

    const refreshFiles = () => {
        refreshData()
    }

    const validateActionHandler = () => {
        return roleId == ADMIN ? [createFolder, createFile, editFiles, deleteFile] : [];
    }

    const fileActions =  validateActionHandler().concat([smallIconsGrid, iconsList, ChonkyActions.DownloadFiles]);

    return (
        <div
            style={{
                minHeight: "60vh",
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
            }}
        >
            <div id="chonky-searchbar" style={{position: "relative"}}>
                <input type="text" placeholder={"Search..."}
                       style={{maxWidth: "16rem", paddingLeft: "2.25rem"}}
                       onChange={onSearchChange}
                       value={searchForm}
                       className="rounded form-control"/>

                <Icon icon="bx:bx-search-alt"
                      color={"#888"}
                      style={{
                          position: "absolute",
                          left: ".75rem",
                          top: "50%",
                          transform: "translateY(-50%)"
                      }}/>

            </div>

            <FullFileBrowser
                files={filesToShow}
                folderChain={folderChain}
                fileActions={fileActions}
                onFileAction={handleFileAction}
                disableDefaultFileActions={actionsToDisable}
                defaultFileViewActionId={ChonkyActions.EnableListView.id}
                i18n={{
                    locale: "id-ID",
                    defaultLocale: "id-ID",
                }}
            />
            <BsModal/>

            <CreateFolderModal
                showModal={showCreateFolderModal}
                toggleModal={toggleCreateFolderModal}
                onSuccess={refreshFiles}
            />

            <CreateFileModal
                showModal={showCreateFileModal}
                toggleModal={toggleCreateModal}
                onSuccess={refreshFiles}
            />

            <EditFileModal
                showModal={showEditFileModal}
                toggleModal={toggleModal}
                onSuccess={refreshFiles}
            />

            <ConfirmAlert
                confirmTitle="Konfirmasi!"
                confirmInfo="Apakah anda yakin akan menyimpan data ini?"
                loadingTitle="Mengirim data..."
                loadingInfo="Tunggu beberapa saat"
                successTitle="Berhasil!"
                successInfo="Data berhasil disimpan"
                failedTitle="Gagal!"
                failedInfo="Folder tidak dapat diedit"
                showConfirm={false}
                showLoading={false}
                showSuccess={false}
                showFailed={showEditFailedModal}
                response={confirmResponse}
            />

<ConfirmAlert
                confirmTitle="Konfirmasi!"
                confirmInfo="Apakah anda yakin akan menghapus data ini?"
                loadingTitle="Menghapus data..."
                loadingInfo="Tunggu beberapa saat"
                successTitle="Berhasil!"
                successInfo="Data berhasil dihapus"
                failedTitle="Gagal!"
                failedInfo="Data gagal dihapus"
                showConfirm={showDeleteFileModal}
                showSuccess={deleteFileSuccess}
                showLoading={deleteFileLoading}
                showFailed={deleteFileFailed}
                response={confirmDeleteResponse}
            />
        </div>
    );
};
